import React, { useEffect, useState } from "react";
import { IoSettings } from "react-icons/io5";
import { TbColorPicker } from "react-icons/tb";
import { TbCricket } from "react-icons/tb";
import { IoBowlingBallOutline } from "react-icons/io5";
import { MdSportsCricket } from "react-icons/md";
import { CiFlag1 } from "react-icons/ci";
import { GiVibratingShield } from "react-icons/gi";
import Header from "../layout/header/Header";
import { IoSearchOutline } from "react-icons/io5";
import { AiOutlineBars } from "react-icons/ai";
import toast from "react-hot-toast";
import {
  displayfilterparameterAction,
  displayNftsAction,
  displayNftsFilterAction,
  displayNftsFilterCountryAction,
  displayNftsFilterSkillAction,
} from "../../Action/user.action";
import CustomLoader from "../dashboard/CustomLoader";
import { GiCricketBat } from "react-icons/gi";


export default function TotalNfts() {
  const [displayNfts, setdisplayNfts] = useState([]);
  const [totaldisplayNfts, settotaldisplayNfts] = useState(0);
  const [FiltertotaldisplayNfts, setFiltertotaldisplayNfts] = useState(0);
  const [homepagelength, sethomepagelength] = useState("");
  const [limit, setlimit] = useState(28);
  const [loader, setloader] = useState(false);
  const [emptyloader, setemptyloader] = useState(true);
  const [displaycountries, setdisplaycountries] = useState([]);
  const [displayskills, setdisplayskills] = useState([]);
  const [displaytournament, setdisplaytournament] = useState([]);

  const [selectedskillfilter, setselectedskillfilter] = useState("");
  const [selectedcountryfilter, setselectedcountryfilter] = useState("");
  const [selectedtournamentfilter, setselectedtournamentfilter] = useState("");

  const [selectedskillfiltername, setselectedskillfiltername] = useState("");
  const [selectedsort, setselectedsort] = useState("");

  const [selectedcountryfiltername, setselectedcountryfiltername] =
    useState("");
    const [selectedtournamentfiltername, setselectedtournamentfiltername] = useState("");

  useEffect(() => {
    displaynftsfunc();
    displaycountriesfunc();
    return () => { };
  }, []);

  window.onscroll = function (ev) {
    if (
      window.innerHeight + window.scrollY + 400 >=
      document.body.scrollHeight
    ) {
      setTimeout(() => {
        loadmore();
      }, 1000);
    }
  };

  const loadmore = async (e) => {
    if (totaldisplayNfts <= limit) {
      return;
    } else {
      setemptyloader(false);
      setTimeout(() => {
        setlimit(limit + 28);
        setemptyloader(true);
      }, 1000);
    }
  };

  const inputhandler = (e) => {
    const { value, name } = e.target;
    let filtereditems = FiltertotaldisplayNfts.filter(
      (item) =>
        item.name.toLowerCase().includes(value.toLowerCase()) ||
        item.nick_name.toLowerCase().includes(value.toLowerCase()) ||
        item.country.toLowerCase().includes(value.toLowerCase()) ||
        item.skill.toLowerCase().includes(value.toLowerCase())
      //  ||
      // item.nft_type.toLowerCase().includes(value.toLowerCase())
    );
    setdisplayNfts(filtereditems);
    settotaldisplayNfts(filtereditems.length);
  };

  const sortinginputhandler = (item) => {
    const sortingOptions = {
      1: "Price : Low to High",
      2: "Price : High to Low",
      3: "Age : Low to High",
      4: "Age : High to Low",
      5: "Rating : Low to High",
      6: "Rating : High to Low",
    };
    const selectedSorts = sortingOptions[item];
    setselectedsort(selectedSorts);
    let sortedNfts = [...FiltertotaldisplayNfts];
    if (item == 1) {
      sortedNfts.sort(
        (a, b) => parseFloat(a.nft_price) - parseFloat(b.nft_price)
      );
    }
    if (item == 2) {
      sortedNfts.sort(
        (a, b) => parseFloat(b.nft_price) - parseFloat(a.nft_price)
      );
    }
    if (item == 3) {
      sortedNfts.sort((a, b) => parseFloat(a.age) - parseFloat(b.age));
    }
    if (item == 4) {
      sortedNfts.sort((a, b) => parseFloat(b.age) - parseFloat(a.age));
    }

    if (item == 5) {
      sortedNfts.sort(
        (a, b) => parseFloat(a.static_rating) - parseFloat(b.static_rating)
      );
    }
    if (item == 6) {
      sortedNfts.sort(
        (a, b) => parseFloat(b.static_rating) - parseFloat(a.static_rating)
      );
    }
    setdisplayNfts(sortedNfts);
  };

  const filterSkillAndCountryFunc = async (
    filterSkillValue,
    filterCountryValue,
    filterSkillName,
    filterCountryName,
    filterTournamentValue,
    filterTournamentName
  ) => {
    console.log(filterSkillValue,"filterSkillValue",
      filterCountryValue,"filterCountryValue",
      filterSkillName,"filterSkillName",
      filterCountryName,"filterCountryName",
      filterTournamentValue,"filterTournamentValue",
      filterTournamentName,"filterTournamentName");
    try {
      setloader(true);

      setselectedskillfilter(filterSkillValue);
      setselectedcountryfilter(filterCountryValue);
      setselectedcountryfiltername(filterCountryName);
      setselectedtournamentfilter(filterTournamentValue)
      setselectedtournamentfiltername(filterTournamentName)
      setselectedskillfiltername(filterSkillName);
      document.getElementById("searchrefs").value = "";
      setselectedsort("");
      let res = await displayNftsFilterCountryAction(
        filterSkillValue,
        filterCountryValue,
        filterTournamentValue
      );
      if (res.success) {
        setdisplayNfts(res.data);
        settotaldisplayNfts(res.data.length);
        setFiltertotaldisplayNfts(res.data);
        setloader(false);
      } else {
        setloader(false);

        toast.error(res.msg);
        setdisplayNfts([]);
      }
    } catch (err) {
      setloader(false);

      console.log(err);
    }
  };

  const handlereset = () => {
    if (selectedsort == "" && selectedskillfiltername == "" && selectedcountryfiltername == "" && selectedtournamentfiltername == "" && document.getElementById("searchrefs").value==""){
      return 
    }
    else {
      displaynftsfunc();
    }
  };

  const displaynftsfunc = async () => {
    try {
      setloader(true);
      setselectedskillfiltername("")
      setselectedcountryfiltername("")
      setselectedskillfilter("");
      setselectedcountryfilter("");
      setselectedsort("")
      setselectedskillfilter("");
      setselectedcountryfilter("");
      setselectedtournamentfilter("")
      setselectedtournamentfiltername("")
      document.getElementById("searchrefs").value="";
      let res = await displayNftsAction();
      if (res.success) {
        setdisplayNfts(res.data);
        settotaldisplayNfts(res.data.length);
        setFiltertotaldisplayNfts(res.data);
        setloader(false);
      } else {
        toast.error(res.msg);
        setdisplayNfts([]);
        setloader(false);
      }
    } catch (err) {
      setloader(false);

      console.log(err);
    }
  };

  const displaycountriesfunc = async () => {
    try {
      let res = await displayfilterparameterAction();
      if (res.success) {
        setdisplaycountries(res.data.country);
        setdisplayskills(res.data.skills);
        setdisplaytournament(res.data.tournament);
      } else {
        toast.error(res.msg);
        setdisplaycountries([]);
      }
    } catch (err) {
      console.log(err);
    }
  };
  return (
    <>
      {/* <Header /> */}
      <div className="container">
        <div className="total_nfts nfts my-5">
          <div className="t_nftsHeader">
            <div className="row">
              <div className="col-lg-3 mb-3">
                <div className="fltrnft w-100">
                  <input
                    type="text"
                    className="form-control px-5"
                    placeholder="Search"
                    onChange={(e) => inputhandler(e)}
                    id="searchrefs"
                  />
                  <span className="icnsrch">
                    <IoSearchOutline />
                  </span>
                </div>
              </div>

              <div className="col-lg-9">
                <div className="row mt-md-0 justify-content-end">
                  <div className="col-6 col-lg-auto text-end mb-3">
                    <div className="d-flex justify-content-md-end">
                      <div className="dropdown  w-100">
                        <button
                          className="btn mt-md-0 btn-secondary dropdown-toggle nfts_btn bg-dark  w-100"
                          type="button"
                          id="dropdownMenuButton1"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        >
                          <div className="d-flex justify-content-end align-items-center">
                            <AiOutlineBars />
                            <span className="ms-1">
                              {selectedskillfiltername == ""
                                ? "Select Skill"
                                : selectedskillfiltername}
                            </span>
                          </div>
                        </button>
                        <ul
                          className="dropdown-menu bg-dark"
                          aria-labelledby="dropdownMenuButton1"
                        >
                          <li>
                            <a
                              className="dropdown-item btn_uni m-2"
                              onClick={() =>
                                filterSkillAndCountryFunc(
                                  0,
                                  selectedcountryfilter,
                                  "",
                                  selectedcountryfiltername,
                                  selectedtournamentfilter,
                                  selectedtournamentfiltername
                                )
                              }
                            >
                              <span>
                                <GiCricketBat />
                              </span>
                              <span className="ms-2">All</span>
                            </a>
                          </li>
                          {displayskills.map((item, index) => {
                            return (
                              <li>
                                <a
                                  className="dropdown-item btn_uni m-2"
                                  onClick={() =>
                                    filterSkillAndCountryFunc(
                                      item.id,
                                      selectedcountryfilter,
                                      item.name,
                                      selectedcountryfiltername,
                                      selectedtournamentfilter,
                                  selectedtournamentfiltername
                                    )
                                  }
                                >
                                  <span>
                                    <img src={item.logo} alt={item.logo} />
                                  </span>
                                  <span className="ms-2">{item.name}</span>
                                </a>
                              </li>
                            );
                          })}
                        </ul>
                      </div>
                    </div>
                  </div>

                  <div className="col-6 col-lg-auto text-end mb-3">
                    <div className="d-flex justify-content-md-end">
                      <div className="dropdown  w-100">
                        <button
                          className="btn mt-md-0 btn-secondary dropdown-toggle nfts_btn bg-dark  w-100"
                          type="button"
                          id="dropdownMenuButton1"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        >
                          <div className="d-flex justify-content-end align-items-center">
                            <AiOutlineBars />
                            <span className="ms-1">
                              {selectedtournamentfiltername == ""
                                ? "Select Tournament"
                                : selectedtournamentfiltername}
                            </span>
                          </div>
                        </button>
                        <ul
                          className="dropdown-menu bg-dark"
                          aria-labelledby="dropdownMenuButton1"
                        >
                          <li>
                            <a
                              className="dropdown-item btn_uni m-2"
                              onClick={() =>
                                filterSkillAndCountryFunc(
                                  selectedskillfilter,
                                  selectedcountryfilter,
                                  selectedskillfiltername,
                                  selectedcountryfiltername,
                                  "",
                                  ""
                                )
                              }
                            >
                              <span>
                                <GiCricketBat />
                              </span>
                              <span className="ms-2">All</span>
                            </a>
                          </li>
                          {displaytournament.map((item, index) => {
                            return (
                              <li>
                                <a
                                  className="dropdown-item btn_uni m-2"
                                  onClick={() =>
                                    filterSkillAndCountryFunc(
                                      selectedskillfilter,
                                      selectedcountryfilter,
                                      selectedskillfiltername,
                                      selectedcountryfiltername,
                                      item.id,
                                      item.short_name
                                    )
                                  }
                                >
                                  <span className="ms-2">{item.short_name}</span>
                                </a>
                              </li>
                            );
                          })}
                        </ul>
                      </div>
                    </div>
                  </div>

                  <div className="col-6 col-lg-auto text-end mb-3 ">
                    <div className="d-flex justify-content-md-end">
                      <div className="dropdown  w-100">
                        <button
                          className="btn mt-md-0 btn-secondary dropdown-toggle nfts_btn bg-dark  w-100"
                          type="button"
                          id="dropdownMenuButton1"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        >
                          <div className="d-flex justify-content-end align-items-center">
                            <AiOutlineBars />
                            <span className="ms-1">
                              {selectedcountryfiltername == ""
                                ? "Select Country"
                                : selectedcountryfiltername}
                            </span>
                          </div>
                        </button>
                        <ul
                          className="dropdown-menu bg-dark"
                          aria-labelledby="dropdownMenuButton1"
                        >
                          <li>
                            <a
                              className="dropdown-item btn_uni m-2"
                              onClick={() =>
                                filterSkillAndCountryFunc(
                                  selectedskillfilter,
                                  0,
                                  selectedskillfiltername,
                                  "",
                                  selectedtournamentfilter,
                                  selectedtournamentfiltername
                                )
                              }
                            >
                              <span>
                                {/* <img src="" className="img-fluid fltr_imgs" alt="" /> */}
                              </span>
                              <span className="ms-2">All</span>
                            </a>
                          </li>
                          {displaycountries.length > 0
                            ? displaycountries.map((item, index) => {
                              return (
                                <li>
                                  <a
                                    className="dropdown-item btn_uni m-2"
                                    onClick={() =>
                                      filterSkillAndCountryFunc(
                                        selectedskillfilter,
                                        item.id,
                                        selectedskillfiltername,
                                        item.name,
                                        selectedtournamentfilter,
                                  selectedtournamentfiltername
                                      )
                                    }
                                  >
                                    <span>
                                      <img src={item?.flag_logo} className="img-fluid fltr_imgs" alt="" />
                                    </span>
                                    <span className="ms-2">{item.name}</span>
                                  </a>
                                </li>
                              );
                            })
                            : "Sorry, no results found!"}
                        </ul>
                      </div>
                    </div>
                  </div>

                  <div className="col-6 col-lg-auto text-end mb-3">
                    <div className="d-flex justify-content-md-end">
                      <div className="dropdown w-100">
                        <button
                          className="btn mt-md-0 btn-secondary dropdown-toggle nfts_btn bg-dark w-100"
                          type="button"
                          id="dropdownMenuButton1"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        >
                          <div className="d-flex justify-content-end align-items-center">
                            <AiOutlineBars />
                            <span className="ms-1">
                              {" "}
                              {!selectedsort ? "Sort by" : selectedsort}
                            </span>
                          </div>
                        </button>
                        <ul
                          className="dropdown-menu bg-dark"
                          aria-labelledby="dropdownMenuButton1"
                        >
                          <li>
                            <a
                              className="dropdown-item btn_uni m-2 text-capitalize"
                              onClick={() => sortinginputhandler(1)}
                            >
                              <span className="ms-2 text-light">
                                Price : Low to High
                              </span>
                            </a>
                          </li>
                          <li>
                            <a
                              className="dropdown-item btn_uni m-2 text-capitalize"
                              onClick={() => sortinginputhandler(2)}
                            >
                              <span className="ms-2">Price : High to Low</span>
                            </a>
                          </li>
                          <li>
                            <a
                              className="dropdown-item btn_uni m-2 text-capitalize"
                              onClick={() => sortinginputhandler(3)}
                            >
                              {/* <span><IoBowlingBallOutline /></span> */}
                              <span className="ms-2">Age : Low to High</span>
                            </a>
                          </li>
                          <li>
                            <a
                              className="dropdown-item btn_uni m-2 text-capitalize"
                              onClick={() => sortinginputhandler(4)}
                            >
                              {/* <span><MdSportsCricket /></span> */}
                              <span className="ms-2">Age : High to Low</span>
                            </a>
                          </li>

                          <li>
                            <a
                              className="dropdown-item btn_uni m-2 text-capitalize"
                              onClick={() => sortinginputhandler(5)}
                            >
                              {/* <span><IoBowlingBallOutline /></span> */}
                              <span className="ms-2">Rating : Low to High</span>
                            </a>
                          </li>
                          <li>
                            <a
                              className="dropdown-item btn_uni m-2 text-capitalize"
                              onClick={() => sortinginputhandler(6)}
                            >
                              {/* <span><MdSportsCricket /></span> */}
                              <span className="ms-2">Rating : High to Low</span>
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>

                  <div className="col-6 col-lg-auto text-end text-lg-end d-flex align-items-md-start align-items-end">
                    <button
                      className="btn_uni text-center w-100"
                      onClick={() => handlereset()}
                    >
                      Reset
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="mt-3 mt-lg-0">
              <p className="m-0">Total NFTs {totaldisplayNfts}</p>
            </div>
          </div>

          <div
            className="tab-pane fade show active text-white"
            id="autopick"
            role="tabpanel"
            aria-labelledby="autopick-tab"
          >
            <div className="row">
              {loader ? (
                <div className="d-flex justify-content-center">
                  <CustomLoader />
                </div>
              ) : displayNfts.length > 0 ? (
                displayNfts.slice(0, limit).map((item, index) => (
                  <div key={index} className="col-lg-3 col-md-4 mt-3">
                    <div className="position-relative">
                      <div className="text-center">
                        <div className="fs-6 t-price">
                          <small className="position-absolute bonus_tag">
                            {item?.category}
                          </small>

                          <span>${item.nft_price}</span>
                        </div>
                        <img src={item.image} alt="" className="cricketcard"onError={(e) => {
                          e.target.src =
                            "https://web3sports.s3.ap-south-1.amazonaws.com/Players/dummy.png";
                        }} />
                        <div className="cardDetail">
                          <div className="cardInnerDetail">
                            <small className="text-primary fw-medium">
                              {/* {item.static_rating.split(".")[0]}/100 */}
                              {Math?.trunc(parseInt(item?.dynamic_rating) + parseInt(item?.static_rating))}/100 
                            </small>
                            {/* <h5 className="mb-0">{item.name}</h5> */}
                            <h5 className="mb-0" data-bs-toggle="tooltip" data-bs-placement="top"
                              title={item.name?.length < '15' ? "" : item.name}>
                              {item.name?.length > '15' ? item.name?.slice(0, 14) + ".." : item.name}
                            </h5>
                            {/* <h5 className="mb-0">{item.name?.length > '15' ? item.name?.slice(0, 14) + ".." : item.name}</h5> */}
                            <small className="text-primary fw-medium">
                              {item.skill}
                            </small>
                          </div>
                          <div className="d-flex justify-content-between">
                            <small className="text-uppercase">
                              Age {item.age}
                            </small>
                            <small className="text-uppercase">
                              <span className="nft_flag_img">
                                <img src={item.flag_logo} className="img-fluid" />
                                <span>{item.country}</span>
                              </span>
                            </small>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))
              ) : (
                <div className="h-100 mt-5">
                        <div className="h-75">
                          <div className="text-center">
                            <img src="ds/nodatapreview.png" alt="" className="img-fluid" style={{ width: '200px' }} />
                          </div>
                          <h4 className="mt-3 text-center">Sorry, no results found!</h4>
                        </div>
                      </div>
              )}
            </div>
          </div>

          {emptyloader == true ? (
            ""
          ) : (
            <div className="d-flex justify-content-center">
              <CustomLoader />
            </div>
          )}
        </div>
      </div>
    </>
  );
}
